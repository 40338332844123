<template>
  <div class="roleInfo">
    <div class="actionBar">

      <a-space>
        <a-input-search size="large" v-model="listParams.keyword" placeholder="请输入关键词搜索" enter-button="查询"
          style="width: 350px" allowClear @search="onSearch" />
        <a-upload name="file" :action="thirdImportThird" :headers="headers" :showUploadList="false" accept=".xls,.xlsx"
          @change="handleChange">
          <a-button type="primary" size="large" :loading="loading" :disabled="loading"> 账户批量上传 </a-button>
        </a-upload>
      </a-space>
      <a-button icon="plus" type="primary" @click="showModal" size="large">
        添加账号</a-button>
    </div>
    <a-modal :title="modalTitle" :visible="visible" :confirm-loading="confirmLoading" @ok="handleOk"
      @cancel="handleCancel" okText="确定" cancelText="取消">
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" :from="addFromData">
        <a-form-item label="登录账号">
          <a-input v-model="addFromData.user_login" />
        </a-form-item>
        <a-form-item label="昵称">
          <a-input v-model="addFromData.user_nickname" />
        </a-form-item>
        <a-form-item label="登录密码">
          <a-input v-model="addFromData.password" :placeholder="modalTitle == '新增管理员' ? '' : '不修改则不填写'" />
        </a-form-item>

      </a-form>
    </a-modal>
    <a-table :columns="columns" :data-source="data" rowKey="id" :pagination="{
      total: count,
      current: listParams.page,
      pageSize: listParams.limit,
      showTotal: (total) => `共 ${total} 条`,
    }" @change="pageChange">

      <span slot="user_login" slot-scope="text, record, index">
        <a @click="showMoadl(record)">{{ text }}</a>
      </span>

      <span slot="user_status" slot-scope="text">
        <a-tag :color="text == 1 ? 'green' : 'orange'">
          {{ text == 1 ? "启用" : "禁用" }}
        </a-tag>
      </span>

      <span slot="action" slot-scope="text, record">
        <FontIcon @click.native="editMenu(record)" title="修改" type="icon-xiugai" size="22"></FontIcon>
        <a-divider type="vertical" />
        <a-switch checked-children="启" un-checked-children="禁" :checked="record.user_status == 1"
          @click="confirm(record)" />
      </span>
    </a-table>


    <a-modal  :visible="visible3" @cancel="visible3 = false" :width="1000" :footer="null">
      <a-card title="账户资料" style="width: 900px">
        <a-row>
          <a-col :span="8">
            姓名：{{ uploaderInformation.user_realname }}
          </a-col>
          <a-col :span="8">
            性别：{{ uploaderInformation.sex == 1 ? '男' : '女' }}
          </a-col>
          <a-col :span="8">
            昵称：{{ uploaderInformation.user_nickname }}
          </a-col>
        </a-row>
        <br>
        <a-row>
          <a-col :span="8">
            手机号：{{ uploaderInformation.mobile }}
          </a-col>
          <a-col :span="8">
            用户名：{{ uploaderInformation.user_login }}
          </a-col>
          <a-col :span="8">
            身份证：{{ uploaderInformation.id_card }}
          </a-col>
        </a-row>
        <br>
        <a-row>
          <a-col :span="8">
            银行卡号：{{ uploaderInformation.bank_no }}
          </a-col>
          <a-col :span="16">

            开户行(精确到支行)：{{ uploaderInformation.bank_name }}
          </a-col>

        </a-row>
      </a-card>
    </a-modal>
  </div>
</template>
  
<script>
import {
  thirdIndex,
  thirdDataSave,
  thirdSetStatus,
  thirdImportThird
} from "@/apis/index";
import moment from "moment";
import local from "@/utils/local";
import { message } from "ant-design-vue";
const columns = [
  {
    title: "ID",
    dataIndex: "id",
    scopedSlots: { customRender: "id" },
  },
  {
    title: "用户名",
    dataIndex: "user_login",
    scopedSlots: { customRender: "user_login" },
  },
  {
    title: "昵称",
    dataIndex: "user_nickname",
    scopedSlots: { customRender: "user_nickname" },
  },

  {
    title: "最后登录时间",
    dataIndex: "last_login_time",
    scopedSlots: { customRender: "last_login_time" },
    customRender: (text, record) => {
      if (text != 0) {
        return moment(Number(text + "000")).format("YYYY-MM-DD HH:mm:ss");
      } else {
        return "";
      }
    },
  },
  {
    title: "状态",
    dataIndex: "user_status",
    scopedSlots: { customRender: "user_status" },
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
    width: 200
  },
];

export default {
  data() {
    return {
      columns,
      data: [],
      visible: false,
      confirmLoading: false,
      modalTitle: "",
      addFromData: {},

      listParams: {
        page: 1,
        limit: 10,
        keyword: "",
      },
      count: 0,


      thirdImportThird,
      headers: {
        "XX-Token": local.get("adminToken"),
        "XX-Device-Type": 'web'
      },
      loading: false,

      visible3: false,
      uploaderInformation: ''
    };
  },
  computed: {
  },
  watch: {
  },
  created() {
    this.getThirdIndex();
  },
  methods: {
    // 上传人信息
    showMoadl(rec) {
      this.visible3 = true
      this.uploaderInformation = rec
      // console.log(rec)
    },


    showModal() {
      this.modalTitle = "新增管理员";
      (this.addFromData = {
        id: "",
        user_login: "",
        user_nickname: "",
        password: "",
        ids: [],
      }),
        (this.visible = true);
    },
    handleOk(e) {
      this.confirmLoading = true;
      thirdDataSave(this.addFromData).then((res) => {
        if (res.code == 1) {
          this.$message.success("成功");
          this.getThirdIndex();
          this.visible = false;
        }
        this.confirmLoading = false;
      });
    },
    handleCancel(e) {
      this.visible = false;
    },
    editMenu(record) {
      this.modalTitle = "修改管理员信息";
      this.addFromData = {
        id: record.id,
        user_login: record.user_login,
        user_nickname: record.user_nickname,
      };
      this.visible = true;
    },
    confirm(rec) {
      thirdSetStatus({
        id: rec.id,
        status: rec.user_status == 1 ? 0 : 1,
      }).then((res) => {
        if (res.code == 1) {
          this.$message.success("success");
          this.getThirdIndex();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    pageChange(a) {
      this.listParams.page = a.current;
      this.getThirdIndex();
    },
    getThirdIndex() {
      thirdIndex(this.listParams).then((res) => {
        this.data = res.data.list;
        this.count = res.data.count;
      });
    },
    onSearch(value) {
      this.listParams.page = 1;
      this.getThirdIndex();
    },
    handleChange(files) {
      if (files.file.status == "uploading") {
        this.loading = true
      }
      if (files.file.status == "done") {
        this.loading = false
        if (files.file.response.code == 0) {
          message.error(files.file.response.msg);
        } else {
          message.success(files.file.response.msg);
          this.getThirdIndex();
        }
      }
    }
  },
};
</script>
  
<style lang="less" scoped>
.roleInfo {
  .actionBar {
    padding: 30px 0;
    display: flex;
    justify-content: space-between;
  }
}
</style>
  